import React, {useState, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {Languages} from '../../api'
import {getStaticSlug} from './common/StaticSlug'
import {ContentBody} from '@dudagroup/editor'

interface PreviewLinkCopyProps {
  content: ContentBody
  isMedia?: boolean
}

function getDefaultContentLink(content: ContentBody, websiteUrl: string, lang: string) {
  return `${websiteUrl}/${lang}/${content.id}/${getStaticSlug(content.contentType, lang)}/${
    content.slugI18n[lang]
  }`
}

function getMediaLink(content: ContentBody, lang: string) {
  const settings = document.getElementById('settings')
  const media = content.content?.media?.[lang]?.media
  if (settings?.textContent && media?.filename && media?.extension) {
    const {mediaPreviewUrl} = JSON.parse(settings.textContent)

    if (
      content.meta.robotsMetaDirectives === 'indexFollow' ||
      content.meta.robotsMetaDirectives === 'indexNoFollow' ||
      !content.meta.robotsMetaDirectives ||
      content.content.media[lang].media.image
    ) {
      return `${mediaPreviewUrl}/media/${lang}/${content.id}/${media.filename}${media.extension}`
    }
    return `${mediaPreviewUrl}/media-noindex/${lang}/${content.id}/${media.filename}${media.extension}`
  }
  return ''
}

export default function PreviewLinkCopy({content, isMedia}: PreviewLinkCopyProps) {
  const {t} = useTranslation()

  const [selectedLang, setSelectedLang] = useState('de')
  const [websiteUrl, setWebsiteUrl] = useState<string | null>(null)
  const [copied, setCopied] = useState(false)

  useEffect(() => {
    const settings = document.getElementById('settings')
    if (settings?.textContent) {
      try {
        const parsedSettings = JSON.parse(settings.textContent)
        setWebsiteUrl(parsedSettings.websiteUrl)
      } catch (err) {
        console.error('Error parsing settings:', err)
      }
    }
  }, [])

  if (!websiteUrl) return null

  const handleCopy = () => {
    const url = isMedia
      ? getMediaLink(content, selectedLang)
      : getDefaultContentLink(content, websiteUrl, selectedLang)

    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(url)
        .then(() => {
          setCopied(true)
          setTimeout(() => setCopied(false), 2000)
        })
        .catch((err) => {
          console.error('Failed to copy: ', err)
        })
    } else {
      const textArea = document.createElement('textarea')
      textArea.value = url
      document.body.appendChild(textArea)
      textArea.select()
      try {
        document.execCommand('copy')
        setCopied(true)
        setTimeout(() => setCopied(false), 2000)
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err)
      }
      document.body.removeChild(textArea)
    }
  }

  return (
    <div style={{display: 'flex', alignItems: 'center'}}>
      <select
        onChange={(e) => setSelectedLang(e.target.value)}
        value={selectedLang}
        style={{border: '1px solid', borderRadius: '5px'}}>
        {Object.values(Languages).map((lang) => (
          <option key={lang} value={lang}>
            {lang.toUpperCase()}
          </option>
        ))}
      </select>
      <button
        style={{marginLeft: '10px', padding: '2px 7px'}}
        className="rs-btn rs-btn-primary"
        onClick={handleCopy}>
        {!copied ? t('global.buttons.linkCopy') : t('global.buttons.linkCopied')}
      </button>
    </div>
  )
}
